/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { getLightTheme } from './theme';
import Login from './Pages/Login/Login';
import PasswordReset from './Pages/PasswordReset/PasswordReset';
import DashboardLayout from './Layout/DashboardLayout';
import Dashboard from './Pages/Dashboard/Dashboard';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as IconClose } from '@mui/icons-material';
import Cogs from './Pages/Cogs/Cogs';
import AuthLayout from './Layout/AuthLayout';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import useAuth from './hooks/useAuthContext';
import StationList from './Pages/StationList/StationList';
import AQHCalculator from './Pages/AQHCalculator/AQHCalculator';
import NotFound from './Pages/NotFound/NotFound';
import SalesToolkit from './Pages/SalesToolkit/SalesToolkit';
import Inventory from './Pages/Inventory/Inventory';
import SalesProspector from './Pages/SalesProspector/SalesProspector';
import AdminPanel from './Pages/AdminPanel/AdminPanel';
import Admin from './Pages/Admin/Admin';
import EmployeeData from './Pages/Employee/EmployeeData';
import Settings from './Pages/Settings/Settings';
import Profile from './Pages/Profile/Profile';
import Documents from './Pages/Documents/Documents';
import {
	collection,
	doc,
	getDoc,
	limit,
	onSnapshot,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import { db } from './firebase/config.js';
import { useNavigate } from 'react-router-dom';
import Notification from './Pages/Notification/Notification';
import LooksGood from './Pages/LooksGood/LooksGood';
import SigningPage from './Pages/LooksGood/components/SigningPage';
import Loading from './components/Loading';
import ReportBugButton from './components/BugReportButton';
import MessagesTab from './Pages/Messages/MessagesTab';
import OptionConfirmation from './Pages/Options/OptionConfirmation';
import VacationCredits from './Pages/Cogs/VacationsCredits.js';
import BillPay from './Pages/Cogs/BillPay.js';
import RewardCards from './Pages/Cogs/RewardCards.js';
import RepCardsHome from './Pages/Cogs/components/AmazonCards/RepCardsHome.js';

const MainBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'authIsReady',
})(({ theme, authIsReady, pathname }) => ({
	backgroundColor:
		(!authIsReady && pathname.includes('dashboard')) || pathname === '/'
			? theme.palette.primary.main
			: 'white',
	minHeight: '100vh',
}));

function SnackbarCloseButton({ id }) {
	const { closeSnackbar } = useSnackbar();

	return (
		<IconButton style={{ color: 'white' }} onClick={() => closeSnackbar(id)}>
			<IconClose />
		</IconButton>
	);
}

const PrivateRoute = ({
	user,
	authIsReady,
	children,
	receivedNoti,
	requiredRole,
}) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!authIsReady || !user) {
			return;
		}

		// Check if the user has the required role
		if (requiredRole && user.role) {
			const hasRequiredRole = user.role.some(
				(role) => role.value === requiredRole
			);
			if (!hasRequiredRole) {
				navigate('/dashboard/AQH');
				return;
			}
		}

		// Redirect to the login page if the user is not logged in
		else if (user === null && authIsReady === false) {
			navigate('/');
			return;
		}
	}, [user, authIsReady, navigate, requiredRole]);

	if (user?.firstName && user?.lastName) {
		return <DashboardLayout children={children} receivedNoti={receivedNoti} />;
	}

	return null;
};

// if go to route, redirect to external link
const ExternalRedirect = ({ to }) => {
	useEffect(() => {
		window.location.href = to;
	}, [to]);
	return (
		<div style={{ textAlign: 'center', paddingTop: '30px' }}>
			Redirecting to the meeting in Zoom...
		</div>
	);
};

const App = () => {
	const { authIsReady, user } = useAuth();
	const background = '#0082A6';
	const navigate = useNavigate();
	const location = useLocation();
	const [receivedNoti, setReceivedNoti] = useState([]);
	const [userFirebaseInfo, setUserFirebaseInfo] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const setUserFirebase = async () => {
		let { uid, email, photoURL } = user;
		const docRef = doc(db, 'users', uid);
		const userSnap = await getDoc(docRef);
		setIsLoading(false);
		if (!userSnap.exists()) {
			await setDoc(docRef, {
				email,
				firstName: '',
				lastName: '',
				photoURL,
				employeeId: '',
			});
			navigate('/dashboard/profile', { state: { isEmpty: true } });
		} else {
			const userData = userSnap.data();
			setUserFirebaseInfo(userData);
			// if user has first name and last name, redirect to dashboard
			if (userData?.firstName && userData?.lastName) {
				location.pathname === '/' && navigate('/dashboard/AQH');
			} else {
				navigate('/dashboard/profile', { state: { isEmpty: true } });
			}
		}
	};

	useEffect(() => {
		if (db && user) {
			const msgRef = collection(db, 'notifications');
			const receiverQ = query(
				msgRef,
				where('receiver_id', '==', user?.uid),
				limit(500)
			);
			const unsubcribe = onSnapshot(receiverQ, (snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					results.push({
						...doc.data(),
						id: doc.id,
						received_message: doc.data().message,
					});
				});
				setReceivedNoti(
					results.sort((a, b) => {
						const aDate = new Date(a?.createdAt?.toDate());
						const bDate = new Date(b?.createdAt?.toDate());

						return bDate.getTime() - aDate.getTime();
					})
				);
				// bottomRefSend && bottomRefSend.current?.scrollIntoView({ behavior: 'smooth' });
				// bottomRefRec && bottomRefRec.current?.scrollIntoView({ behavior: 'smooth' });
			});
			return unsubcribe;
		}
	}, [db, user]);

	useEffect(() => {
		authIsReady && Object.keys(user).length > 0 && setUserFirebase();
		setIsLoading(false);
	}, [authIsReady, user]);

	//wait for auth to be ready
	if (isLoading) return <Loading />;
	return (
		<ThemeProvider theme={getLightTheme(background)}>
			<MainBox
				authIsReady={authIsReady}
				pathname={location.pathname}
				sx={{
					height: '100%',
					transform: 'translateZ(0px)',
					flexGrow: 1,
				}}
			>
				<SnackbarProvider
					action={(key) => <SnackbarCloseButton id={key} />}
					hideIconVariant
					autoHideDuration={3000}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<Routes>
						<Route path="/" element={<AuthLayout children={<Login />} />} />
						<Route
							path="/password-reset"
							element={<AuthLayout children={<PasswordReset />} />}
						/>
						<Route path="/register" />
						<Route
							exact
							path="/dashboard"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<Dashboard />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						{/* Start Router Cogs */}
						<Route
							exact
							path="/dashboard/cogs"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<Cogs />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/vacationCredits"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<VacationCredits />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/reward-cards"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<RewardCards />}
									receivedNoti={receivedNoti}
									requiredRole="giftCardManager"
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/billPay"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<BillPay />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/cardsHome"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<RepCardsHome />}
									receivedNoti={receivedNoti}
									requiredRole="giftCardManager"
								/>
							}
						/>
						{/* End Router Cogs */}
						<Route
							exact
							path="/dashboard/AQH"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<StationList />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/aqhcalculator"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<AQHCalculator />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/toolkit"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<SalesToolkit />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/inventory"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<Inventory />}
									receivedNoti={receivedNoti}
								/>
							}
						/>

						<Route
							path="/dashboard/prospector"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<SalesProspector />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/admindata"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<AdminPanel />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/messages"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<MessagesTab />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/admin"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<Admin />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/employee-data"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<EmployeeData />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/settings"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<Settings />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/profile"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<Profile />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/documents"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<Documents />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							exact
							path="/dashboard/notification-center"
							element={
								<PrivateRoute
									user={userFirebaseInfo}
									authIsReady={authIsReady}
									children={<Notification />}
									receivedNoti={receivedNoti}
								/>
							}
						/>
						<Route
							path="/:inviterId/:inviteeEmail/:emailId"
							element={<LooksGood />}
						/>
						<Route path="/onboarding/sign/:documentHash" element={<SigningPage />} />
						<Route path="/:proposalId/:optionId" element={<OptionConfirmation />} />
						<Route
							path="/zoom"
							element={
								<ExternalRedirect to="https://us02web.zoom.us/j/85701302578?pwd=TWVqMk5pYVEyQTBIdnpvWGI5d0NkUT09" />
							}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</SnackbarProvider>
				<Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
					<ReportBugButton />
				</Box>
			</MainBox>
		</ThemeProvider>
	);
};

export default App;
