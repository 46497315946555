/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { db } from '../../../firebase/config';
import {
	collection,
	query,
	where,
	getDocs,
	onSnapshot,
	doc,
	updateDoc,
	deleteField,
	limit,
} from 'firebase/firestore';
import {
	Box,
	CircularProgress,
	Grid,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
	Autocomplete,
	Collapse,
	Alert,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
	format,
	addWeeks,
	isMonday,
	previousFriday,
	isFriday,
	subWeeks,
	nextMonday,
	differenceInCalendarWeeks,
} from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
	tableRowHeadersName,
	getNumberOfDays,
} from '../../SalesProspector/utils/constants';
import {
	getUniqueArr,
	splitArrayIntoChunks,
	addDashSimulcast,
	numberWithCommas,
} from '../../../utils';
import {
	rowHeadersName,
	initialDateState,
	cptConstant,
	newAQH,
} from '../utils/constants';
import {
	dateTimeStamp,
	getNextMonday,
	getPreviousFriday,
} from '../utils/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	proposalId,
	setProposalId,
	setBridgeObj,
	bridgeObj,
	disabled,
	setOpenBridgeDealModal,
	openBridgeDealModal,
	setOpenDeleteBridgeModal,
	openDeleteBridgeModal,
	startDateNewOne,
}) {
	const [deal, setDeal] = useState('');
	const [loading, setLoading] = React.useState(false);
	const [deleteLoading, setDeleteLoading] = React.useState(false);
	const [openStartDate, setOpenStartDate] = React.useState(false);
	const [openEndDate, setOpenEndDate] = React.useState(false);
	const [dateState, setDateState] = useState(initialDateState);
	let { startDate, endDate, noOfWeeks } = dateState;
	const [dealStations, setDealStations] = useState([]);
	const [updateAqh, setUpdateAqh] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [sales, setSales] = useState([]);
	const salesRef = collection(db, 'sales');
	const scheduleRef = collection(db, 'schedule');
	const [cptRatio, handleCptRatioChange] = useState([1, 685, 4]);
	const [bridgeDeal, setBridgeDeal] = useState(false);
	const [canceled, setCanceled] = useState(false);
	const seasonsRef = collection(db, 'seasons');
	const [currentRef, setCurrentRef] = useState(null);
	const [pastRatingsRef, setPastRatingsRef] = useState(null);
	const [completed, setCompleted] = useState(false);

	// get a current and past seasons collection
	useEffect(() => {
		const getSeasons = async () => {
			const q = query(seasonsRef, limit(1));
			const seasonsDocs = await getDocs(q);
			let seasonsResults = [];
			seasonsDocs.forEach((doc) => {
				seasonsResults.push({ ...doc.data(), seasonId: doc.id });
			});
			setCurrentRef(seasonsResults[0]?.currentSeason);
			setPastRatingsRef(seasonsResults[0]?.pastSeason);
		};
		getSeasons();
	}, []);

	useEffect(() => {
		// set bridge deal data if bridge deal data exist on proposal
		if (bridgeObj) {
			setDeal(bridgeObj.dealNumber);
			const savedDates = {
				startDate: {
					value: dateTimeStamp(bridgeObj.startDate),
					isChanged: true,
				},
				endDate: {
					value: dateTimeStamp(bridgeObj.endDate),
					isChanged: true,
				},
				noOfWeeks: {
					value: bridgeObj?.noOfWeeks,
					isChanged: true,
				},
			};
			setDateState(savedDates);
			setBridgeObj(bridgeObj);
			setBridgeDeal(true);
		}
	}, [bridgeObj, canceled]);

	useEffect(() => {
		//get sales
		const salesQuery = query(salesRef);
		const unsubSnap = onSnapshot(salesQuery, (snapshot) => {
			const sales = snapshot.docs.map((doc) => doc.data());
			setSales(sales);
		});

		return unsubSnap;
	}, []);

	// filter sales to dont show empty zero or N/A on Deal filter.
	const filteredPreviousSales = sales.filter((sale) => {
		const filtered =
			sale['Deal #'] !== '' && sale['Deal #'] !== '0' && sale['Deal #'] !== 'N/A';
		return filtered;
	});

	// remove duplicated and sort Previous Deal # to show it on Deal Filter.
	const uniqueSales = [
		...new Set(filteredPreviousSales.map((item) => item['Deal #']).sort()),
	];

	// get dates from new deal, previous deal and no have bridge deal
	useEffect(() => {
		if (dealStations && dealStations.length > 0 && !bridgeObj && completed) {
			// start date from deal selected
			const startDate = dealStations[0]?.endDate || null;
			// end date from new proposal
			const endDate = startDateNewOne || null;

			if (startDate && endDate) {
				// Define adjusted start and end dates
				const adjustedStartDate = new Date(startDate);
				const adjustedEndDate = new Date(endDate);
				// Verify if startDate is not a Monday
				if (adjustedStartDate.getDay() !== 1) {
					const nextMonday = getNextMonday(adjustedStartDate);
					adjustedStartDate.setDate(nextMonday.getDate());
				}
				// Adjust end date to the previous Friday
				const previousFriday = getPreviousFriday(adjustedEndDate);
				adjustedEndDate.setDate(previousFriday.getDate());
				// Set the new dates
				const dates = {
					startDate: {
						value: dateTimeStamp(adjustedStartDate),
						isChanged: true,
					},
					endDate: {
						value: dateTimeStamp(adjustedEndDate),
						isChanged: true,
					},
					noOfWeeks: {
						value: 0,
						isChanged: true,
					},
				};
				setDateState(dates);
			}
		}
	}, [completed]);

	// dates for Deal filter
	const allDatesInput = useMemo(() => {
		let dates = {
			endDate: endDate.value,
			startDate: startDate.value,
			noOfWeeks: noOfWeeks.value,
		};
		if (startDate.value && noOfWeeks.value > 0) {
			const wks = addWeeks(startDate.value, noOfWeeks.value);
			const endDate = previousFriday(wks);
			dates = { ...dates, endDate };
		} else if (endDate.value && noOfWeeks.value > 0) {
			const wks = subWeeks(endDate.value, noOfWeeks.value);
			const startDate = nextMonday(wks);
			dates = { ...dates, startDate };
		} else if (startDate.value && endDate.value) {
			const noOfWeeks = differenceInCalendarWeeks(endDate.value, startDate.value, {
				weekStartsOn: 1,
			});
			// transform in absolute number
			dates = { ...dates, noOfWeeks: Math.abs(noOfWeeks + 1) };
		}
		return dates;
	}, [startDate, noOfWeeks, endDate, startDateNewOne, dealStations, deal]);

	const checkDisabled = (name) => {
		if (name === 'startDate') {
			return (noOfWeeks.isChanged && endDate.isChanged) || !deal ? true : false;
		} else if (name === 'endDate') {
			return (noOfWeeks.isChanged && startDate.isChanged) || !deal ? true : false;
		} else if (name === 'noOfWeeks') {
			return (startDate.isChanged && endDate.isChanged) || !deal ? true : false;
		}
	};

	const handleResetInputs = () => setDateState(initialDateState);

	const handleChange = (name, value) => {
		if (name === 'startDate') {
			setDateState({ ...dateState, startDate: { value, isChanged: true } });
		} else if (name === 'endDate') {
			setDateState({ ...dateState, endDate: { value, isChanged: true } });
		} else if (name === 'noOfWeeks') {
			// validate only integer numbers from 0 to 9, with regex.
			const regex = /^[0-9\b]+$/;
			if ((value === '' || regex.test(value)) && value <= 1000) {
				// limited because show an error with dates after 1000 weeks
				setDateState({
					...dateState,
					noOfWeeks: { value: value, isChanged: true },
				});
			} else if ((value === '' || regex.test(value)) && value > 1000) {
				// if user type more than 1000 weeks by default set 1000 weeks
				setDateState({ ...dateState, noOfWeeks: { value: 1000, isChanged: true } });
			}
		}
	};

	// get all documents from schedule collection when deal change
	useEffect(() => {
		if (deal) {
			const scheduleQuery = query(scheduleRef, where('Deal', '==', deal));
			const dealData = [];
			const result = [];
			// get all documents from schedule collection
			const unsubSnap = onSnapshot(scheduleQuery, (snapshot) => {
				// if documents exist, get data from each document and push it to dealData array
				if (!snapshot.empty) {
					snapshot.forEach((doc) => {
						const data = {
							station: doc.data()?.Calls || null,
							dayPart: doc.data()?.Daypart || null,
							noOfSpots: doc.data()?.['MPW by daypart'] || null,
							endDate: doc.data()?.['End date'] || null, // end date from deal
							weeks: doc.data()?.Weeks || null,
						};
						dealData.push(data);
						result.push(doc.data().Calls);
					});
					// Capitalize stations
					const handleStations = dealData.map((item) => ({
						...item,
						station: item.station?.toUpperCase(),
					}));

					// Group by station and dayPart, keep highest weeks
					const groupedData = {};
					handleStations.forEach((item) => {
						const key = item.station + '-' + item.dayPart;
						if (!groupedData[key] || +groupedData[key].weeks < +item.weeks) {
							groupedData[key] = item;
						}
					});
					// Get unique items
					const unique = Object.values(groupedData);

					// Filter duplicates keeping higher weeks
					const uniqueDealData = unique.filter((item) => {
						const isDuplicate = unique.find(
							(element) =>
								element.station === item.station &&
								element.weeks < item.weeks &&
								element.dayPart !== item.dayPart
						);
						return !isDuplicate;
					});
					setDealStations(uniqueDealData);
				}
			});
			return unsubSnap;
		}
	}, [deal]);

	// get stations data from ratings collection
	useEffect(() => {
		if (dealStations && dealStations.length > 0) {
			if (currentRef && pastRatingsRef) {
				const ref = collection(db, currentRef);
				const pastRef = collection(db, pastRatingsRef);
				let allStations = dealStations.map((v) => v.station);
				allStations = [...new Set(allStations)];

				///verify if undefined exists in stations array
				const filteredStations = allStations.filter(function (x) {
					return x !== undefined;
				});
				let spliSalesIntoSmallArr = splitArrayIntoChunks(10, filteredStations);
				let batchArr = [];
				let batchArrPast = [];

				spliSalesIntoSmallArr.map(async (batch, idx) => {
					const q = query(ref, where('Station', 'in', batch));
					const pastQ = query(pastRef, where('Station', 'in', batch));

					let resultsPast = [];
					// Past Ratings
					const querySnapshot = await getDocs(pastQ);
					querySnapshot.docs.forEach((doc) => {
						resultsPast.push({ ...doc.data(), id: doc.id });
					});
					batchArrPast = [...batchArrPast, ...resultsPast];

					// Current Ratings
					const currQuerySnapshot = await getDocs(q);

					let results = [];
					currQuerySnapshot.docs.forEach((doc) => {
						results.push({ ...doc.data(), id: doc.id });
					});
					batchArr = [...batchArr, ...results];

					let ratingsArr = batchArr.map((data) => {
						let getAqh = batchArrPast?.find(
							(sprObj) => sprObj['Station'] === data['Station']
						);
						return { ...data, pastBook: getAqh };
					});

					if (spliSalesIntoSmallArr.length === idx + 1) {
						// use a dealStations array
						let mergeData = dealStations.map((data) => {
							let getAqh = ratingsArr?.find(
								(sprObj) => sprObj['Station'] === data['station']
							);
							if (getAqh) {
								return {
									...getAqh,
									Simulcast: data.Simulcast ? addDashSimulcast(data.Simulcast) : '',
									pastBook: getAqh?.pastBook,
								};
							}
							return null;
						});
						// removed null values from mergeData array
						mergeData = mergeData.filter((element) => {
							return element !== null;
						});
						setUpdateAqh(getUniqueArr(mergeData, ['Station', 'id']));
					}
				});
			}
		}
	}, [dealStations, currentRef, pastRatingsRef]);

	// set values to table
	useEffect(() => {
		let dataArr = [];
		if (updateAqh?.length > 0) {
			updateAqh.map((c) => {
				let obj = {};
				rowHeadersName.map((name) =>
					Object.assign(obj, {
						[name]:
							name === 'Station' || name === 'Format'
								? c[name]
								: {
										orignalValue: c[name]
											? newAQH(c[name], c.pastBook[name], c.Format, c.Station)
											: c.Simulcast || 0,
										noOfSpots: 0,
								  },
					})
				);
				return dataArr.push(Object.values(obj));
			});
		}
		setTableData(dataArr);
		if (dataArr.length > 0) {
			setCompleted(true);
		}
	}, [updateAqh]);

	// get previous deal data from deals collection
	useEffect(() => {
		if (
			dealStations &&
			dealStations.length > 0 &&
			tableData &&
			tableData.length > 0
		) {
			dealStations.forEach((deal) => {
				// get table index to replace it after find same daypart on saved previous deal data
				const index = tableData.findIndex((item) => item[0] === deal.station);
				if (index !== -1) {
					if (deal?.dayPart) {
						// two ifs handle unique dayparts from previous deals
						if (
							deal?.dayPart === '6a-12m' ||
							deal?.dayPart === '12m-6a' ||
							deal?.dayPart === '6a-12n'
						) {
							const dayPartIndex =
								tableRowHeadersName.findIndex(
									(daypart) => daypart === `MSu ${deal?.dayPart}`
								) + 2;
							const SSDayPartIndex =
								tableRowHeadersName.findIndex(
									(daypart) => daypart === `SS ${deal?.dayPart}`
								) + 2;
							if (dayPartIndex !== -1) {
								// previous deals has Minutes per weeks so we need to divide by 7 to get the number of spots
								tableData[index][dayPartIndex].noOfSpots = deal?.noOfSpots / 7 || 0;
							} else if (SSDayPartIndex !== -1) {
								// previous deals has Minutes per weeks so we need to divide by 2 to get the number of spots
								tableData[index][SSDayPartIndex].noOfSpots = deal?.noOfSpots / 2 || 0;
							}
						} else {
							// this get the index of the daypart from the table from previous deals adding MF, Sat or Sun to the daypart
							const dayPartIndex =
								tableRowHeadersName.findIndex(
									(daypart) => daypart === `MF ${deal?.dayPart}`
								) + 2;
							const satDayPartIndex =
								tableRowHeadersName.findIndex(
									(daypart) => daypart === `Sat ${deal?.dayPart}`
								) + 2;
							const sunDayPartIndex =
								tableRowHeadersName.findIndex(
									(daypart) => daypart === `Sun ${deal?.dayPart}`
								) + 2;
							// set the number of spots from previous deal
							if (dayPartIndex !== -1) {
								// previous deals has Minutes per weeks so we need to divide by 5 to get the number of spots
								tableData[index][dayPartIndex].noOfSpots = deal?.noOfSpots / 5 || 0;
							} else if (satDayPartIndex !== -1) {
								tableData[index][satDayPartIndex].noOfSpots = deal?.noOfSpots || 0;
							} else if (sunDayPartIndex !== -1) {
								tableData[index][sunDayPartIndex].noOfSpots = deal?.noOfSpots || 0;
							}
						}
					}
				}
			});
		}
	}, [tableData]);

	// get weekly seconds
	const getWeeklySeconds = (noOfSpots, dayPartString) => {
		const seconds = noOfSpots * 2;
		return seconds * getNumberOfDays(dayPartString);
	};

	// get data table with values
	const getLiveTableData = useMemo(() => {
		let getData = {};
		tableData.length > 0 &&
			tableData.forEach((col) =>
				col.forEach((val, rowIdx) => {
					if (typeof val === 'object' && val?.noOfSpots > 0) {
						handleCptRatioChange((prev) =>
							prev[1] > cptConstant[rowHeadersName[rowIdx]][1]
								? prev
								: cptConstant[rowHeadersName[rowIdx]]
						);
						const dayPartString = rowHeadersName[rowIdx].slice(0, 3);
						const aqh =
							typeof val?.orignalValue === 'string' ? 0 : val?.orignalValue || 0;
						const gross =
							(aqh / 128340600) *
							100 *
							2200 *
							getWeeklySeconds(val?.noOfSpots, dayPartString) *
							allDatesInput.noOfWeeks *
							0.85;
						getData[`${col[0]}@${val?.noOfSpots}/${rowIdx}`] = {
							orignalValue: aqh || 0,
							noOfSpots: val.noOfSpots,
							dayPartName: rowHeadersName[rowIdx],
							impressions: Number(
								aqh * val.noOfSpots * 2 * getNumberOfDays(dayPartString)
							),
							gross: Number(gross).toFixed(2),
							4.1: Number(gross / 4.1).toFixed(2),
							695: Number(gross / 695).toFixed(2),
							4.2: Number(gross / 4.2).toFixed(2),
							705: Number(gross / 705).toFixed(2),
							4.3: Number(gross / 4.3).toFixed(2),
							715: Number(gross / 715).toFixed(2),
							4.4: Number(gross / 4.4).toFixed(2),
							725: Number(gross / 725).toFixed(2),
							4.5: Number(gross / 4.5).toFixed(2),
							735: Number(gross / 735).toFixed(2),
							4.6: Number(gross / 4.6).toFixed(2),
							745: Number(gross / 745).toFixed(2),
						};
					}
				})
			);
		return getData;
	}, [tableData, allDatesInput?.noOfWeeks, dealStations]);
	// calculate gross total of deal
	const grossTotal = useMemo(() => {
		// if getLiveTableData is empty, return grossTotal from bridgeObj
		if (Object.keys(getLiveTableData).length === 0) {
			if (bridgeObj) {
				return Number(bridgeObj.grossTotal).toFixed(2);
			} else {
				return '0.00'; // or return 0
			}
		} else {
			// if getLiveTableData is not empty, calculate grossTotal
			let total = [];
			Object.keys(getLiveTableData).filter((key) =>
				total.push(Number(getLiveTableData[key].gross))
			);
			total = total.reduce((a, b) => a + b, 0);
			return Number(total).toFixed(2);
		}
	}, [getLiveTableData, bridgeObj, allDatesInput]);

	// save data and send to main object
	const handleSave = async () => {
		setLoading(true);
		try {
			const data = {
				dealNumber: deal,
				getLiveTableData,
				grossTotal,
				startDate: allDatesInput.startDate,
				noOfWeeks: allDatesInput.noOfWeeks,
				endDate: allDatesInput.endDate,
				endDateContract: dealStations[0]?.endDate, // get end date in sheet or firebase from first station
				dayParts: { ...tableData },
			};
			// update proposal Bridge Deal if proposalId exist
			if (proposalId && proposalId !== '') {
				const proposalRef = doc(db, 'savedProposals', proposalId);
				await updateDoc(proposalRef, {
					bridgeDeal: data,
				});
			}
			// obj to send to main object
			setBridgeObj(data);
			// if exist bridge deal
			setBridgeDeal(true);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setOpenBridgeDealModal(false);
		}
	};

	// handle cancel button
	const handleCancel = () => {
		setCanceled(true);
		setOpenBridgeDealModal(false);
	};

	// handle remove button
	const handleRemove = async () => {
		setDeleteLoading(true);
		// remove bridge deal from proposal
		if (proposalId && proposalId !== '') {
			const proposalRef = doc(db, 'savedProposals', proposalId);
			await updateDoc(proposalRef, {
				bridgeDeal: deleteField(),
			});
			setTableData([]);
			setDateState(initialDateState);
			setDealStations([]);
			setUpdateAqh([]);
			setDeal('');
			setBridgeDeal(false);
			setBridgeObj(null);
			// reload proposal data after delete bridge deal
			setProposalId(proposalId);
		} else {
			// if proposalId not exist, remove bridge deal from main object
			setBridgeObj(null);
			setBridgeDeal(false);
		}
		setDeleteLoading(false);
		setOpenDeleteBridgeModal(false);
	};

	return (
		<div>
			<Dialog
				open={openBridgeDealModal}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpenBridgeDealModal(false)}
				aria-describedby="alert-dialog-slide-description"
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '350px',
					},
				}}
			>
				<DialogTitle>Bridge Component</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							my: 2,
							'& >*': {
								my: 1,
							},
						}}
					>
						<Stack direction={'row'} spacing={2}>
							<Typography>Deal Number</Typography>
							<Box style={{ width: '50%', paddingBottom: 20 }}>
								<Autocomplete
									options={uniqueSales.map((sale) => sale) || []}
									getOptionLabel={(option) => option}
									isOptionEqualToValue={(option, value) => option === value}
									id="previousDeal"
									size="small"
									value={deal}
									onChange={(_, newValue) => setDeal(newValue)}
									renderInput={(params) => <TextField {...params} variant="standard" />}
								/>
							</Box>
						</Stack>

						<Box sx={{ width: '100%' }}>
							<Collapse in={!deal}>
								<Alert sx={{ mb: 2 }} severity="info">
									Remember, first select a Deal Number!
								</Alert>
							</Collapse>
						</Box>
					</Box>
					<Typography style={{ paddingTop: 5 }}></Typography>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<Grid container spacing={1}>
							<Grid item>
								<DatePicker
									open={openStartDate}
									onOpen={() => setOpenStartDate(true)}
									onClose={() => setOpenStartDate(false)}
									components={{ OpenPickerIcon: CalendarMonthIcon }}
									label="Start Date"
									value={allDatesInput.startDate}
									onChange={(newValue) => handleChange('startDate', newValue)}
									disabled={checkDisabled('startDate')}
									shouldDisableDate={(day) => !isMonday(day)}
									InputProps={{
										disabled: true,
										value: allDatesInput.startDate
											? format(allDatesInput.startDate, 'MM/dd/yy')
											: '',
										inputProps: { readOnly: true },
									}}
									renderInput={(params) => (
										<TextField
											disabled={true}
											size="small"
											{...params}
											sx={{ minWidth: '130px', maxWidth: '151px', color: 'black' }}
											onClick={(e) => setOpenStartDate(true)}
											error={false}
											helperText="Current deal end date"
										/>
									)}
								/>
							</Grid>
							<Grid item>
								<TextField
									name="noOfWeeks"
									size="small"
									variant="outlined"
									label="No of Weeks"
									sx={{ minWidth: '100px', maxWidth: '130px' }}
									value={allDatesInput.noOfWeeks}
									onChange={(e) => handleChange('noOfWeeks', e.target.value)}
									disabled={checkDisabled('noOfWeeks')}
									type="text"
									autoComplete="off"
								/>
							</Grid>
							<Grid item>
								<Box display="inline-flex" alignItems="center">
									<DatePicker
										open={openEndDate}
										onOpen={() => setOpenEndDate(true)}
										onClose={() => setOpenEndDate(false)}
										components={{ OpenPickerIcon: CalendarMonthIcon }}
										label="End Date"
										value={allDatesInput.endDate}
										onChange={(newValue) => handleChange('endDate', newValue)}
										disablePast={true}
										disabled={checkDisabled('endDate')}
										shouldDisableDate={(day) => !isFriday(day)}
										InputProps={{
											disabled: true,
											value: allDatesInput.endDate
												? format(allDatesInput.endDate, 'MM/dd/yy')
												: '',
											inputProps: { readOnly: true },
										}}
										renderInput={(params) => (
											<TextField
												disabled={true}
												size="small"
												{...params}
												sx={{ minWidth: '130px', maxWidth: '151px' }}
												onClick={(e) => setOpenEndDate(true)}
												error={false}
												helperText="Proposal start date"
											/>
										)}
									/>
									<Tooltip title="Reset">
										<span>
											<IconButton
												size="small"
												color="info"
												sx={{ marginLeft: '10px', marginTop: '-25px' }}
												onClick={handleResetInputs}
												disabled={!deal}
											>
												<RestartAltIcon />
											</IconButton>
										</span>
									</Tooltip>
								</Box>
							</Grid>
						</Grid>
					</LocalizationProvider>
					<Box sx={{ pt: 5 }}>
						<Typography sx={{ mt: 2, mb: 1 }}>
							Gross Total: $ {numberWithCommas(grossTotal)}
						</Typography>
					</Box>
				</DialogContent>
				<DialogActions sx={{ mr: 2, pb: 2 }}>
					<Button variant="outlined" color="error" onClick={handleCancel}>
						Cancel
					</Button>
					<Button
						onClick={handleSave}
						variant="contained"
						startIcon={loading && <CircularProgress size={18} />}
						disabled={
							loading ||
							!deal ||
							!allDatesInput.startDate ||
							!allDatesInput.endDate ||
							!allDatesInput.noOfWeeks ||
							grossTotal === '0.00'
						}
					>
						SAVE
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openDeleteBridgeModal}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpenDeleteBridgeModal(false)}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>{'Remove Bridge Component'}</DialogTitle>
				<DialogContent>
					<Typography>Are you sure to remove the bridge component?</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setOpenDeleteBridgeModal(false)}
						variant="outlined"
						color="info"
					>
						No
					</Button>
					<Button
						onClick={handleRemove}
						variant="contained"
						color="error"
						startIcon={deleteLoading && <CircularProgress size={18} />}
						disabled={deleteLoading}
					>
						YES
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
