import React from 'react';
import { CSVLink } from 'react-csv';
import Button from '@mui/material/Button';
import moment from 'moment';

const ExportCSV = ({ data }) => {
	// Flattening nested objects
	const flattenObject = (obj, prefix = '') => {
		return Object.keys(obj).reduce((acc, key) => {
			const value = obj[key];
			const newKey = prefix ? `${prefix}_${key}` : key;

			if (value && typeof value === 'object' && !moment.isMoment(value)) {
				if (value.seconds !== undefined) {
					// Handle Firestore timestamps
					acc[newKey] = moment(value.seconds * 1000).format('MM/DD/YYYY');
				} else if (Array.isArray(value)) {
					// Handle arrays
					acc[newKey] = value
						.map((item) => item.note || item)
						.filter(Boolean)
						.join(', ');
				} else {
					// Handle nested objects
					Object.assign(acc, flattenObject(value, newKey));
					console.log(acc);
				}
			} else {
				// Handle any other type of value
				acc[newKey] = value;
			}
			return acc;
		}, {});
	};

	// Procesar y aplanar todos los datos
	const flattenedData = data.map((item) => flattenObject(item));

	// Get all possible keys from all records
	const allKeys = Array.from(new Set(flattenedData.flatMap(Object.keys)));

	// Normalize data to ensure that all rows have all columns
	const normalizedData = flattenedData.map((row) => {
		const normalizedRow = {};
		allKeys.forEach((key) => {
			normalizedRow[key] = row[key] !== undefined ? row[key] : '';
		});
		return normalizedRow;
	});

	// Generate headers for CSV file
	const headers = allKeys.map((key) => ({
		label: key
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' '),
		key: key,
	}));

	const filename = `orderCards_${moment().format('MM/DD/YYYY')}.csv`;

	return (
		<CSVLink
			data={normalizedData}
			headers={headers}
			filename={filename}
			style={{ textDecoration: 'none' }}
		>
			<Button variant="contained" color="primary">
				Export CSV
			</Button>
		</CSVLink>
	);
};

export default ExportCSV;
